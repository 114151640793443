const initBlock = async () => {
  const sections = Array.from(document.getElementsByClassName('b-read-also'));

  for (const section of sections) {
    const sliderEl = section.querySelector('.swiper');

    if (!sliderEl) {
      continue;
    }

    const {Swiper} = await import('swiper');

    new Swiper(sliderEl, {
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
  }
};

window.requestAnimationFrame(async function check() {
  document.body ? await initBlock() : window.requestAnimationFrame(check);
});

if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
